



































































import { Component, Mixins } from 'vue-property-decorator';
import ElePropertiesMixins from './mixins/ElePropertiesMixins';

/**
 * input的配置项
 */
@Component({
  name: 'TreeConfig',
  components: {}
})
export default class TreeConfig extends Mixins(ElePropertiesMixins) {
  public valueTypes = [
    {
      id: 'ALL',
      label: '全部'
    },
    {
      id: 'LEAF_PRIORITY',
      label: '叶子结点'
    },
    {
      id: 'BRANCH_PRIORITY',
      label: '分支节点'
    }
  ];
}
